
import { get } from "../../services/fetch"
import { useEffect, useRef, useState } from "react";
import ElectionResultItem from "../layouts/ElectionResultItem";
import { Table, theme } from "antd";
import board from '../../assets/images/boardicon.png'
import tune from '../../assets/images/tuneVector.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ViewUploadedReport from "../layouts/ViewUploadedReport";
import { groupBy } from "../../utility/general";


const queryString = window.location.search;

const params = new URLSearchParams(queryString);

const LiveResult = (props) => {

    const [electionResults, setElectionResults] = useState([]);
    const [parties, setParties] = useState([]);
    const [partyResults, setPartyResults] = useState([])
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState("manage")
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [columnKey, setColumnKey] = useState(0);
    const tableRef = useRef(null);

    let state;
    const id = params.get('id');
    console.log(...electionResults)
    if(props.location.state){
          state  = props.location.state.state;
    }
   
    console.log(props.location.state)

    const [columns, setColumns] = useState([
        {
            title: <img src={tune} alt="whiteBoardIcon" srcset="" />,
            key: 'action',
            width: 80,
            fixed: "left",
            ellipsis: true,
            render: (text, record) => (
                <img onClick={() => handleRecord(record)} src={board} className="pointer" alt="whiteBoardIcon" srcset="" />
            ),
        },
        // {
        //     title: 'Agent Name',
        //     dataIndex: 'returningOfficer',
        //     key: 'returningOfficer',
        //     width: 150,
        //     ellipsis: true,
        //     fixed: "left",

        // },
        {
            title: 'Polling Unit',
            dataIndex: 'pollingUnit',
            key: 'pollingUnit',
            width: 150,
            ellipsis: true,
            fixed: "left",

        },
        {
            title: 'Registered Voters',
            dataIndex: 'registeredVoters',
            width: 150,
            ellipsis: true,

            key: 'regVoters',
            // fixed: "left",
        },
        {
            title: 'Accredited Voters',
            dataIndex: 'accreditedVoters',
            width: 150,
            ellipsis: true,

            key: 'accrVoters',
            // fixed: "left",
        },
        {
            title: 'Valid Votes',
            key: 'validVote',
            dataIndex: 'validVote',
            // fixed: "right",
            width: 150,
            ellipsis: true,


        },
        {
            title: 'Rejected Votes',
            key: 'rejectedVotes',
            dataIndex: 'rejectedVote',
            // fixed: "right",
            width: 150,
            ellipsis: true,


        },
        {
            title: 'Total Votes Cast',
            key: 'totalVotes',
            dataIndex: 'totalVotes',
            width: 150,
            ellipsis: true,
            fixed: "right",
        },

    ])

    const getParties = async () => {
        let results = await get({ endpoint: "party" });
        console.log(results)
        setParties(results?.data?.data?.map((item) => ({
            title: item?.name,
            dataIndex: item?.name,
            key: item?.name,
            width: 80,
            ellipsis: true,
        })));
    }


    useEffect(() => {
        const interval = setInterval(() => {
          getData(state?.lgaId) // Update the state every 5 seconds
        }, 5000);
      
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
      }, [])


    useEffect(() => {
        getData(state?.lgaId);
        getState(88);
        getParties();
    }, [state]);

    // useEffect(() => {
    //     getState(88) 
    // }, []);




    const getState = async (state) => {
        // setLoading(true);
        // let result = await get({ endpoint: `ElectionResult/state/${state}` });
      
        // setElectionResults(result.data.data.map((item) => {
        //     return {
        //         ...item,
        //         ...item?.partyVotes.reduce((accumulator, item) => {
        //             // Set the party name as the key and votes as the value
        //             accumulator[item?.party] = item.votes;
        //             return accumulator;
        //         }, {})
        //     }
        // }))

        // // setElectionResults(result?.data?.data);
        // setLoading(false);
    }

    useEffect(() => {
        if (parties?.length > 1) {
            handleInsert()
        }
        

    }, [parties]);


    const handleInsert = () => {
        setLoading(true);
        setColumns((prev) => prev?.toSpliced(4, 0, ...parties));
        setLoading(false);
    };



    console.log(id);

    console.log(columns)

    const getData = async (id) => {
        setLoading(true);
        if (!id) {
            setLoading(false);
            return;
        }
        else {
            let result = await get({ endpoint: `ElectionResult/ward/${id}` });
            setElectionResults(result.data.data.map((item) => {
                return {
                    ...item,
                    ...item?.partyVotes.reduce((accumulator, item) => {
                        // Set the party name as the key and votes as the value
                        accumulator[item?.party] = item.votes;
                        return accumulator;
                    }, {})
                }
            }))
            // setElectionResults(result?.data?.data);
            // setParties(result?.data.data?.map((item) => ({
            //     title: item?.party,
            //     dataIndex: item?.id,
            //     key: item?.id,
            //     width: 150,
            //     ellipsis: true,

            // })))
            

            setLoading(false);
        }
    }

    const handleRecord = async (record) => {
        console.log(record)
        setColumnKey(record)
        let res = await get({ endpoint: `electionResult/pollingUnit/${record.pollingUnitId}` })
        if (res?.data?.code === 1) {
            setModalData(res?.data?.data);
            setShowModal(true);
        }
    }


    // useEffect(() => {
    //     handleInsert()
    // }, [parties]);


    // const handleInsert = () => {
    //     setLoading(true);
    //     setColumns(columns?.toSpliced(4, 0, ...parties));
    //     setColumnKey((prev)=> prev + 1)
    //     setLoading(false);
    // };





    return (
        <div>
            <div>
                <div className="m-b-10"><b>{new Date().getFullYear()} Election Result Manager</b> | Live Result</div>
                {/* <div>Search    Filter</div> */}
            </div>

            <h2 className="m-b-10">Polling Unit Election Stats</h2>
            <div className="w-100 ant-table-wrapper">
                <Table
                    ref={tableRef}
                    // loading={loading}
                    style={{maxWidth: '80vw'}}
                    bordered
                    // scroll={{ x: 1000 }}
                    pagination={false}
                    columns={columns}
                    dataSource={[...electionResults]}
                    key={columnKey}
                />
            </div>


            {showModal && <ViewUploadedReport data={modalData}  closeModal={() => setShowModal(false)} handleRecord={handleRecord} columnKey={columnKey}/>}


        </div>
    )
}

export default LiveResult;