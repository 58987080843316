import React from 'react';
import { Link } from 'react-router-dom';

const ForgotPassword = () => (
  <div className="center-40">
    <div className="flex flex-direction-v p-20 flex-v-center login-cont h-100 white-background">
      <h3 className="padded-bottom center-text m-b-20">
        Forgot Password
      </h3>
      <form action="" className="w-100 ">
        <label htmlFor="email">Email Address</label>
        <input className="w-100 p-10 m-b-20" type="email" name="email" id="email" />
        <button className="w-100 p-10 background-gradient color-white m-b-20" type="submit">Continue</button>
      </form>
      <div className="flex space-between w-100 ">
         <Link to={"/"}><p>Return to login</p></Link>
        {/* <Link><p>New to this admin portal?</p></Link> */}
        
      </div>
    </div>
  </div>
);

export default ForgotPassword;
