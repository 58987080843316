export const toggleScroll = () => {
  const overlays = document.querySelectorAll('.overlay');

  if (overlays.length > 0) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }
};

export const groupBy = (array, key) => {
  return array.reduce((result, item) => {
    // Get the value of the key for the current item
    const groupKey = item[key];
    
    // Initialize the array if it does not exist yet
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    
    // Add the item to the appropriate group
    result[groupKey].push(item);
    
    return result;
  }, {});
};

export const convertImgToBase64 = (inputFile) => {
  if (inputFile === undefined) return '';
  const file = new FileReader();

  return new Promise((resolve, reject) => {
    file.onerror = () => {
      file.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    file.onload = () => {
      resolve(file.result);
    };
    file.readAsDataURL(inputFile);
  });
};

export const numberWithCommas = (x) => {
  if (x === '') {
    return
  }
  if (String(x).includes(',')) {
    return String(x)?.replace(/,/g, '')?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  else {
    return x?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

  }

}

export const formatFileUrl = (path) => `${process.env.REACT_APP_BACKEND_URL}/${path}`;
