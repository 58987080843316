import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

// import EdsgLogo from '../../assets/images/edsg-logo-250.png';

const Sidebar = ({ menuList }) => {
  // const { location: { path } } = history;
  const location = useLocation();
  const path = location.pathname;

  return (
    <nav className="page-sidebar">

      <div className="sidebar-header">
        <img src="/APPlogo.jpeg" alt="logo" className="brand h-100 w-fit" width="150" />
      </div>

      <div className="sidebar-menu">
        <ul className="menu-items">
          {menuList && menuList.map((item) => (
            <MenuItem props={item} path={path} key={item.title} />
          ))}
        </ul>
      </div>
    </nav>
  );
};

const MenuItem = ({
  props: {
    title, href, icon, children
  }, path
}) => {
  const [isShowingSub, setIsShowingSub] = useState(false);



  return (
    <>
      <li className={`${path === href ? 'active' : ''}`}>
        {icon}
        {children ? (
          <>
            <Link to={"#"} onClick={() => setIsShowingSub(!isShowingSub)} className="has-sub-menu pointer">
              <span className="title">{title}</span>
            </Link>
            {children && <IoIosArrowBack className={`${isShowingSub ? 'open' : ''} arrow`} />}
          </>
        ) : (
          <Link to={href}>
            <span className="title">{title}</span>
          </Link>
        )}
      </li>
      {children && isShowingSub && (
        <ul className={`${isShowingSub ? 'show' : ''} sub-menu`}>
          {children && children.map((sub) => (
            <li className={`${path === sub.href ? 'active' : ''}`} key={sub.title}>
              <Link to={sub.href}>{sub.title}</Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default Sidebar;
