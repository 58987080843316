import React from 'react';

const Footer = () => (
  <footer>
    <div className="content space-between flex-v-center">
      <div className="footer-inner-left">
        <p>{new Date().getFullYear()} Patriot manager</p>
      </div>
    </div>
  </footer>
);

export default Footer;
