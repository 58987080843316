import React, { useEffect, useState } from 'react';
import avartar from '../../assets/images/user-avater.png'
import { get } from '../../services/fetch';



const ElectionResultItem = ({item}) => {

    const [date] = useState(new Date().toDateString());
    const [time] = useState(new Date().toLocaleTimeString());

    return (
        <div className="w-100 flex space-between flex-v-center m-b-20">
            <div className="flex flex-v-center p-20 ">
                <img
                    className="circle-pic m-r-10"
                    src={
                        // item?.photo
                        //   ? `data:image/png;base64, ${item?.photo}`
                        //   :
                        avartar
                    }
                    alt="candidate Picture"
                />
                <div>
                    <h2>
                        {`${item?.name || "N/A"}`}
                    </h2>
                    <div className="">
                        <span className="m-r-20">
                            <b>State</b>: {item?.state || "N/A"}
                        </span>
                   
                    </div>
                </div>
            </div>
            <div className="flex " style={{ alignItems: "center" }}>
                <div>
                    <div className="center-text">{item?.presidencyCount || "0"}</div>
                    <div className="green-outline">Presidency</div>
                    <div className="center-text">{date | time}</div>
                </div>
                <div>
                    <div className="center-text">
                        {item?.gorvernorshipCount || "0"}
                    </div>
                    <div className="pink-outline">Governorship</div>
                    <div className="center-text">{date | time}</div>

                </div>
                <div>
                    <div className="center-text">{item?.senateCount || "0"}</div>
                    <div className="amber-outline">Senatorial</div>
                    <div className="center-text">{date | time}</div>

                </div>
                <div className="m-r-10 center-text">
                    <div className="center-text">{item?.repsCount || "0"}</div>
                    <div className="lightblue-outline">House of Reps</div>
                    <div className="center-text">{date | time}</div>

                </div>

                <div className="center-text">
                    <div className="center-text">{item?.assemblyCount || "0"}</div>
                    <div className="lightblue-outline">House of Ass</div>
                    <div className="center-text">{date | time}</div>

                </div>
                <div className="p-20  flex-basis-15 dropdown-container">
                    <button type="submit" className="btn w-100 dropdown-btn">
                        View Details
                    </button>
                    <div className="dropdown-menu">
                        <a href="#" className="dropdown-content">
                            View Details
                        </a>
                        {/* <a href="#" className="dropdown-content" onClick={assignCandidate}>Assign Candidate</a> */}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ElectionResultItem;
