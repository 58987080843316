import { Button, Checkbox, Dropdown, Input, Modal, Popconfirm, Radio, Select, Space, Table, Tooltip } from "antd";
import { DatePicker } from "antd";
import { useEffect, useState } from "react";
import { del, get, patch, post } from "../../services/fetch";
import dayjs from "dayjs";
import notification from "../../utility/notification";
import DataTable from "react-data-table-component";
import { GrEdit } from "react-icons/gr";
import AsyncSelect from 'react-select/async';
import ReactSelect from "react-select";
import paths from "../../services/endpoints";
import { GiReturnArrow } from "react-icons/gi";
import { MdDelete } from "react-icons/md";

const { RangePicker } = DatePicker;

const { Option } = Select;

const ManageElectionSettings = () => {

    const [initiate, setInitiate] = useState(true);
    const [initiateData, setInitiateData] = useState();
    const [accessData, setAccessData] = useState();
    const [accessTypes, setAccessTypes] = useState([]);
    const [checkList, setCheckList] = useState([]);
    const [active, setActive] = useState(false);
    const [users, setUsers] = useState([]);
    const [years, setYears] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [states, setStates] = useState([]);
    const [lgas, setLgas] = useState([]);
    const [wards, setWards] = useState([]);
    const [pollingUnit, setPollingUnit] = useState([]);
    const [pollingUnitId, setPollingUnitId] = useState(0);
    const [userId, setUserId] = useState(0);

    const [lgaValue, setLgaValue] = useState(null);
    const [wardValue, setWardValue] = useState(null);
    const [pollValue, setPollValue] = useState(null);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        console.log(userId);
        setIsModalOpen(false);
        let res = await post({ endpoint: "user/agent", body: { userId: userId, pollingUnitId: pollingUnitId } })
        if (res?.data?.code === 1) {
            notification({
                title: 'Success',
                message: res?.data?.message,
                type: 'success',
                duration: 5000,
            })
        }
        else {
            notification({
                title: 'Error',
                message: res?.data?.message,
                type: 'danger',
                duration: 5000,
            })
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [payload, setPayload] = useState({
        yearId: '',
        typeId: 0,
        isActive: false,
        startDate: new Date(),
        endDate: new Date(),
    })


    const getStates = async () => {
        const res = await get({ endpoint: paths.States });
        let tempState = res?.data?.data
        tempState?.unshift({ id: "", value: "", state: "Select State" });
        setStates(
            tempState.map((item) => ({
                value: item?.id,
                label: item?.state,
            }))
        );
    };

    const getLgas = async (state) => {
        const res = await get({ endpoint: `${paths.lga}/State/${Number(state)}` });
        let tempLga = res?.data?.data;
        tempLga?.unshift({ id: "", value: "", lga: "Select LGA" });
        setLgas(
            tempLga?.map((item) => ({
                value: item?.id,
                label: item?.lga,
            }))
        );
    };

    const getWards = async (lga) => {
        const res = await get({ endpoint: `${paths.Ward}/lga/${lga}` });
        let tempWard = res?.data?.data
        tempWard?.unshift({ id: "", value: "", ward: "Select Ward" });
        setWards(
            tempWard?.map((item) => ({
                value: item?.id,
                label: item?.ward,
            }))
        );
    };

    const getPollingUnit = async (wardId) => {
        const res = await get({
            endpoint: `${paths.getPollingUnitByWard}/${wardId}`,
        });
        let tempPolls = res?.data?.data
        tempPolls?.unshift({ id: "", value: "", name: "Select Polling Unit" });
        setPollingUnit(
            tempPolls?.map((item) => ({
                value: item?.id,
                label: item?.name,
            }))
        );
    };

    const handleChange = (e) => {
        console.log(e)
        if (!isNaN(e?.target?.name)) {
            let check = checkList.find((item) => item === e.target.name);
            if (check) {
                setCheckList(checkList.filter((item) => item !== e.target.name));
            } else {
                setCheckList([...checkList, e.target.name]);
            }

        }
        else if (e?.target?.name === "typeId") {
            console.log(e.target.value)
            setPayload((prev) => ({ ...prev, typeId: e.target.value }))
        }
        else {
            setPayload((prev) => ({ ...prev, [e?.target?.name]: e }))
        }


    };


    const handleChangeYear = (e) => {
        console.log(e)
        setPayload((prev) => ({ ...prev, yearId: e }))
    };


    const getElectionYear = async () => {
        let res = await get({ endpoint: "electionYear/getAll" })
        console.log(res.data.data)
        setYears(res?.data?.data);
    }



    const searchUser = async (e) => {
        setLoading(true)
        let res = await get({ endpoint: `user/agents/?query=${searchParam}&pollingUnitId=${pollingUnitId}` })
        console.log(res);
        if (res?.data?.code === 1) {
            setUsers(res?.data?.data);
            setLoading(false)
            setPollValue(null);
            setLgaValue(null);
            setWardValue(null);
        }
        else {
            notification({
                title: 'Error',
                message: 'User not found',
                type: 'danger',
                duration: 5000,
            })
            setLoading(false)
        }


    }


    const validatePayload = () => {

        if (!payload?.yearId) {
            notification({
                title: 'Error',
                message: 'Please select election year',
                type: 'danger',
                duration: 5000,
            })
            return false;
        }
        else if (!payload?.typeId) {
            notification({
                title: 'Error',
                message: 'Please select election type',
                type: 'danger',
                duration: 5000,
            })
            return false;
        }
        else if (!payload?.startDate || !payload?.endDate) {
            notification({
                title: 'Error',
                message: 'Please select date range',
                type: 'danger',
                duration: 5000,
            })
            return false;
        }

        return true;
    }

    const handleUserId = (record) => {
        console.log(record);
        setUserId(record?.id);
    }


    const handleInputChange = async (e) => {
        console.log(e.target.value)
    }

    const handleDateChange = async (e) => {
        setPayload((prev) => ({
            ...prev,
            startDate: dayjs(e[0]).format(),
            endDate: dayjs(e[1]).format(),
        }))
    }

    const handleChangeActive = (e) => {
        console.log(e.target.value)
        setPayload((prev) => ({ ...prev, isActive: !e.target.value }))
    }

    console.log(checkList);

    const getTypes = async () => {
        let res = await get({ endpoint: "position" })
        console.log(res.data.data)
        setAccessTypes(res?.data?.data);
    }

    const getUsers = async () => {
        let res = await get({ endpoint: `users/agents` })
        console.log(res.data.data)
        // setUsers(res?.data?.data);
    }

    const submit = async () => {
        let valid = validatePayload();
        if (!valid) {
            return;
        }
        else {
            let res;
            if (edit) {
                res = await patch({ endpoint: "currentElection", body: payload })
            }
            else {
                res = await post({ endpoint: "currentElection", body: payload })
            }
            if (res.data.statusCode === 1) {
                notification({
                    title: 'Success',
                    message: res?.data?.message,
                    type: 'success',
                    duration: 5000,
                })
            }
            else {
                notification({
                    title: 'Error',
                    message: res?.data?.message,
                    type: 'danger',
                    duration: 5000,
                })
            }
            console.log(res)
        }


    }

    const getPosition = async () => {
        console.log(payload)
        let res = await get({ endpoint: "currentElection" })
        setTableData(res?.data?.data);
        console.log(res)
    }

    const handleAction = (record) => {
        setEdit(true);
        console.log(record?.yearId)
        console.log(years)
        setPayload({
            isActive: record?.isActive,
            typeId: accessTypes.find((type) => type.name === record.title)?.id,
            yearId: years?.find((year) => year?.year.toString() === record?.year.toString()).id,
            startDate: dayjs(record?.startDate).format(),
            endDate: dayjs(record?.endDate).format(),
        })
        console.log(record);
    }

    useEffect(() => {
        getTypes();
        // getUsers();
        getElectionYear();
        getPosition();
        getStates();
        getLgas(88);
    }, [])

    const itemOptions = [
        {
            key: '1',
            label: (
                <span onClick={showModal}>
                    Add Agent
                </span>
            ),
        }
       
    ];



    const accessColumns = [
        {
            title: "Name",
            dataIndex: "fullName",
            key: "fullName",
        },
        {
            title: "Polling Unit",
            dataIndex: "pollingUnit",
            key: "pollingUnit",
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
        },
        {
            title: "Action",
            dataIndex: "name",
            key: "name",
            render: (text, record) => (

                <Space>
                    <Dropdown
                        menu={{
                            items: itemOptions,
                        }}
                        trigger={['click']}
                    >
                        <Tooltip title="Add Agent">
                            <GrEdit className="pointer" fontSize={20} onClick={() => handleUserId(record)} />
                        </Tooltip>
                    </Dropdown>

                    <Tooltip title="Remove Agent">
                        <Popconfirm
                            title="REMOVE AGENT"
                            description="Are you sure to remove this agent?"
                            onConfirm={confirmRemove}
                            onCancel={cancelRemove}
                            okText="Yes"
                            cancelText="No"
                        >
                            <GiReturnArrow className="pointer" fontSize={20} onClick={() => handleUserId(record)} />
                        </Popconfirm>

                    </Tooltip>
                    {/* <Tooltip title="Delete User">
                        <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this user?"
                            onConfirm={confirm}
                            onCancel={cancelPop}
                            okText="Yes"
                            cancelText="No"
                        >
                            <MdDelete color="red" className="pointer" fontSize={20} onClick={() => handleUserId(record)} />
                        </Popconfirm>

                    </Tooltip> */}

                </Space>

            )
        },
    ]

    const initiateColumns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Year",
            dataIndex: "year",
            key: "year",
        },
        {
            title: "Active",
            dataIndex: "isActive",
            key: "isActive",
            render: (text, record) => record.isActive === true ? "Active" : "Inactive"
        },
        {
            title: "Start Date",
            dataIndex: "startDate",
            key: "startDate",
            render: (text, record) => dayjs(record.startDate).format('YYYY-MM-DD')
        },
        {
            title: "End Date",
            dataIndex: "endDate",
            key: "endDate",
            render: (text, record) => dayjs(record.endDate).format('YYYY-MM-DD')
        },
        {
            title: "Action",
            render: (text, record) => <GrEdit className="pointer" fontSize={20} onClick={() => handleAction(record)} />
        },
    ]

    const confirm = async (e) => {
        console.log(e);
        let res = await del({ endpoint: `user/${userId}` });
        if (res.data.statusCode === 1) {
            notification({
                title: 'Success',
                message: res?.data?.message,
                type: 'success',
                duration: 5000,
            })

        }
        else {
            notification({
                title: 'Error',
                message: res?.data?.message,
                type: 'danger',
                duration: 5000,
            })
        }

    };
    const cancelPop = (e) => {
        console.log(e);

    };

    const confirmRemove = async (e) => {
        console.log(e);
        let res = await post({ endpoint: `user/remove/agent?UserId=${userId}` });
        if (res.data.statusCode === 1) {
            notification({
                title: 'Success',
                message: res?.data?.message,
                type: 'success',
                duration: 5000,
            })

            searchParam && searchUser(searchParam)

        }
        else {
            notification({
                title: 'Error',
                message: res?.data?.message,
                type: 'danger',
                duration: 5000,
            })
        }

    };
    const cancelRemove = (e) => {
        console.log(e);

    };

    return (
        <div className="w-100">
            <h1 className="m-b-20">Election manager</h1>
            <hr />

            <div className="w-100 flex m-t-40">
                <div className="flex-basis-15 m-r-60">
                    <div onClick={() => setInitiate(true)} className={`${initiate ? "active-square" : ""} square-labels flex-basis-20 m-b-20`}>
                        Initiate Election
                    </div>
                    <div onClick={() => setInitiate(false)} className={`${!initiate ? "active-square" : ""} square-labels`}>
                        Agent Management
                    </div>
                </div>
                <div className="flex flex-basis-80 white-background p-20">
                    {initiate ?
                        <div className="flex-basis-40 flex flex-direction-v">
                            <h3 className="m-b-20">Initiate Election Year</h3>
                            <b className="m-b-5">Select Period:</b>
                            <RangePicker value={[dayjs(payload?.startDate), dayjs(payload?.endDate)]} minDate={dayjs(new Date())} onChange={handleDateChange} addonBefore="Select Period" className="m-b-20" />

                            <Checkbox onChange={handleChangeActive} checked={payload?.isActive} value={payload?.isActive} className="m-b-20">Active Election</Checkbox>

                            <b className="m-b-5">Year:</b>
                            <Select
                                style={{
                                    width: "80%",
                                    marginBottom: "20px"
                                }}
                                name="yearId"
                                onChange={handleChangeYear}
                                value={payload?.yearId}
                            >
                                {years?.map((user) => (
                                    <Option value={user?.id}>
                                        {user?.year}
                                    </Option>
                                ))
                                }
                            </Select>

                            <b className="m-b-20">Select all that applies:</b>
                            <Radio.Group name="typeId" onChange={handleChange} value={payload.typeId}>
                                {
                                    Array.isArray(accessTypes)
                                    && accessTypes.length > 1
                                    && accessTypes?.map((item) => (
                                        <Radio key={item?.id} name="typeId" value={item?.id} className="m-b-20">{item?.name}</Radio>
                                    ))
                                }

                            </Radio.Group>


                            <Button style={{ backgroundColor: "#05AAE9", color: "white" }} onClick={submit}>Submit</Button>


                        </div>
                        :
                        <div className="flex-basis-30 w-100 flex flex-direction-v">
                            <h3 className="m-b-20">Search by User</h3>
                            <Input
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Enter name of user"
                                onChange={(e) => setSearchParam(e.target.value)}
                            />

                            <hr />

                            <h3 >Search by Polling Unit</h3>

                            <p className="m-t-20">Select LGA:</p>
                            <ReactSelect options={lgas} value={lgaValue} onChange={(value) => {getWards(value?.value); setLgaValue(value)}} />

                            <p className="m-t-20">Select Ward:</p>
                            <ReactSelect options={wards} value={wardValue} onChange={(value) => {getPollingUnit(value?.value); setWardValue(value)}} />

                            <p className="m-t-20">Select Polling Unit:</p>
                            <ReactSelect options={pollingUnit} value={pollValue} onChange={(value) => {setPollingUnitId(value?.value); setPollValue(value)}} />


                            <button onClick={searchUser} className="btn m-t-20">Search</button>

                            {/* <b className="m-b-20 m-t-20">Select Election:</b>
                            {
                                Array.isArray(accessTypes)
                                && accessTypes.length > 1
                                && accessTypes?.map((item) => (
                                    <Checkbox key={item?.id} checked={checkList.includes(item?.id)} name={item?.id} onChange={handleChange} className="m-b-20">{item?.name}</Checkbox>
                                ))
                            }


                            <Button onClick={submit}>Submit</Button> */}
                        </div>

                    }
                    <div className="flex-basis-70 m-l-20">
                        <Table
                            dataSource={initiate ? tableData : users}
                            columns={initiate ? initiateColumns : accessColumns}
                            loading={loading}
                            
                        />
                    </div>

                </div>
            </div>

            <Modal title="Select Polling Unit" open={isModalOpen} onOk={handleOk} okText="Add Agent" onCancel={handleCancel}>

                {/* <p>Select State:</p>
                <ReactSelect options={states} /> */}

                <p>Select LGA:</p>
                <ReactSelect options={lgas} onChange={(value) => getWards(value?.value)} />

                <p className="m-t-20">Select Ward:</p>
                <ReactSelect options={wards} onChange={(value) => getPollingUnit(value?.value)} />

                <p className="m-t-20">Select Polling Unit:</p>
                <ReactSelect options={pollingUnit} onChange={(value) => setPollingUnitId(value?.value)} />


            </Modal>

        </div>
    )

}

export default ManageElectionSettings;