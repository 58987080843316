import React, { useEffect, useState } from 'react';

import PortalRoutes from '../routes/PortalRoutes';
import Sidebar from './layouts/Sidebar';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import menuList from '../config/sidebarMenu';
import Cookies from 'js-cookie';

function UserPortal(props) {
  const  history  = useHistory();

  const SESSION_TIMEOUT_DURATION = 1000 * 600 * 10; // 10 minutes

  const [sessionTimeout, setSessionTimeout] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      if (Date.now() - sessionTimeout > SESSION_TIMEOUT_DURATION) {
        console.log("Session timed out");
        history.push("/");
        Cookies.remove("token");

      }
    }, 1000); // Check every second

    return () => clearInterval(interval);
  }, [sessionTimeout]);

  function handleUserActivity() {
    setSessionTimeout(Date.now());
  }

  
  return (
    <div
      onMouseMove={handleUserActivity}
      onClick={handleUserActivity}
      onKeyDown={handleUserActivity}
      onScroll={handleUserActivity}
      onKeyUpCapture={handleUserActivity}
      onLoad={handleUserActivity}
      className="container"
    >
      <Sidebar menuList={menuList} />
      <div className="portal">
        <Header navList={["Home", "Notification", "Set Reminder"]} />
        <div className="content main-content">
          <PortalRoutes />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default UserPortal;
