/* eslint-disable */

const paths = {
  States:"state",
  Ward:"ward",
  pollingUnit:"pollingUnit",
  getPollingUnitByWard:"PollingUnit/Ward",
  position:"position",
  party:"party",
  lga:"lga",
  gender:"gender",
  candidates:"candidates",
  updateCandidates:"candidates",
  deleteCandidates:"candidate",
  getCanvassersByParty:"Canvasser/GetCanvassers",
  getCanvassersByCandidate:"Canvasser/GetCanvasserByCandidate",
  getCanvassersVsReferral:"Canvasser/GetCanvasserVsReferal",
  getPosition:"position",
  getPArty:"party",
  getCandidatePatriots:"Patriot/getPatriotsByCandidates",
  getPatriots:"Patriot/getPatriots",
  getPatriotReferrals:"Invitations/GetPatriotReferals",
  getCandidates: "Candidates",
  login:"Auth",
  register:"Auth/User/RegisterAdmin",
  changePassword: "Auth/User/ResetPassword",
  getCanvasserStatByPartyId:"Canvasser/GetCanvasserStatsByPartyId",
  getCanvassersByVin:"Canvasser/GetCanvasserByVin"
};

export default paths;
