import React from 'react';
import { Switch, Route } from 'react-router';
import { pure } from 'recompose';

import ProtectedRoute from './ProtectedRoute';
import { SpinnerDotted } from 'spinners-react';
// import Dashboard from '../components/pages/Dashboard';
import MdaSetup from '../components/pages/MdaSetup';
// import RolesSetup from '../components/pages/RolesSetup';
// import EmployeeProfile from '../components/pages/EmployeeProfile';
// import EmployeeDetails from '../components/pages/EmployeeDetails';
// import SupervisorOrganogram from '../components/pages/SupervisorOrganogram';
// import ManageCandidate from '../components/pages/ManageCandidate';
// import ManageCanvassers from '../components/pages/ManageCanvassers';
// import TargetSetting from '../components/pages/TargetSetting';
// import Reporting from '../components/pages/Reporting';
// import Settings from '../components/pages/Settings';
import { Suspense } from 'react';
import Cookies from 'js-cookie';
import LiveResult from '../components/pages/LiveResult';
import ManageElectionSettings from '../components/pages/ManageElectionSettings';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Dashboard = React.lazy(() => import('../components/pages/Dashboard'));
const ManageCandidate = React.lazy(() => import('../components/pages/ManageCandidate'));
const ManageCanvassers = React.lazy(() => import('../components/pages/ManageCanvassers'));
const TargetSetting = React.lazy(() => import('../components/pages/TargetSetting'));
const Reporting = React.lazy(() => import('../components/pages/Reporting'));
const Settings = React.lazy(() => import('../components/pages/Settings'));
const ElectionManager = React.lazy(() => import('../components/pages/ElectionManager'));
const LiveWardResult = React.lazy(() => import('../components/pages/LiveWardResult'));
const LiveLgaResult = React.lazy(() => import('../components/pages/LiveLgaResult'));
const LiveStateResult = React.lazy(() => import('../components/pages/LiveStateResult'));


export default pure(() => (
  <Switch>
      <ProtectedRoute
      path="/patriot-manager/"
      exact
      component={Dashboard}
    /> 
    <ProtectedRoute
      path="/patriot-manager/manage-candidate"
      exact
      component={ManageCandidate}
    />
    <ProtectedRoute
      path="/patriot-manager/result-manager/view-live-result"
      exact
      component={ElectionManager}
    />
     <ProtectedRoute
      path="/patriot-manager/result-manager/live-result"
      exact
      component={LiveResult}
    />
     <ProtectedRoute
      path="/patriot-manager/result-manager/live-lga-result"
      exact
      component={LiveLgaResult}
    />
      <ProtectedRoute
      path="/patriot-manager/result-manager/live-state-result"
      exact
      component={LiveStateResult}
    />
    <ProtectedRoute
      path="/patriot-manager/result-manager/live-ward-result"
      exact
      component={LiveWardResult}
    />
    <ProtectedRoute
      path="/patriot-manager/manage-canvassers"
      exact
      component={ManageCanvassers}
    />
    <ProtectedRoute
      path="/patriot-manager/target-setting"
      exact
      component={TargetSetting}
    />
    <ProtectedRoute
      path="/patriot-manager/reporting"
      exact
      component={Reporting}
    />
    <ProtectedRoute
      path="/patriot-manager/settings/reset-password"
      exact
      component={Settings}
    />
    <ProtectedRoute
      path="/patriot-manager/settings/manage-election"
      exact
      component={ManageElectionSettings}
    />
    <ProtectedRoute
      path="/patriot-manager/logout"
      exact
      component={()=>{
        
        // history.push("/")
        window.location.assign("/")
        Cookies.remove("token");
      
      }}
    />
    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
