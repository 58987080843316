import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
import { SpinnerDotted } from 'spinners-react';

import { ReactNotifications } from 'react-notifications-component';
import Login from './components/home/Login';
import SignUp from './components/home/SignUp';
import ProtectedRoute from './routes/ProtectedRoute';
import UserPortal from './components/UserPortal';
import ForgotPassword from './components/home/ForgotPassword';
import { Suspense } from 'react';
import Cookies from 'js-cookie';
import { ConfigProvider } from 'antd';

function App() {
  // const history = createBrowserHistory();



  return (
    <Suspense
      fallback={
        <SpinnerDotted
          className="center"
          size={200}
          color="blue"
          style={{ backgroundColor: "transparent" }}
        />
      }
    >
      <ConfigProvider
        theme={{
          components: {
            Table:{
              headerBg: "#CDF1FF"
            }
          },
        }}
      >
        <Router>
          <div>
            <ReactNotifications />
            <Switch>
              <Route path="/" exact component={Login} />
              <Route path="/signup" exact component={SignUp} />
              <Route path="/forgot-password" exact component={ForgotPassword} />

              <ProtectedRoute path="/patriot-manager/" component={UserPortal} />
              {/* <Route render={() => <h1>Error 404. Page not found.</h1>} /> */}
            </Switch>
          </div>
        </Router>
      </ConfigProvider>
    </Suspense>

  );
}

export default App;
