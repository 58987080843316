import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import paths from '../../services/endpoints';
import { get, post } from '../../services/fetch';
import notification from '../../utility/notification';
import SelectInput from '../inputs/SelectInput';

const SignUp = () => {

  const [parties, setParties] = useState([]);


  const [formData, setFormData] = useState(
    {
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      password: "",
      confirmPassword: "",
      partyId: 0
    }
  );


  const getParties = async () => {
    const res = await get({ endpoint: paths.party })
    setParties(res?.data?.data?.map((item) => ({ id: item.id, value: item.id, name: item.name })))
    console.log(res)
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })

  }

  const registerUser = async (e) => {
    e.preventDefault();
    const res = await post({endpoint:paths.register, body:formData});
    if(res?.data?.code === 1){
      notification({
        title: 'Success',
        message: "You have successfully created an account, please go back and sign in.",
        type: 'success',
        duration: 5000,
      })
    }
    console.log(res)
  }


  useEffect(()=>{
    getParties();
  }, [])

  return (
    <div className="center-40">
      <div className="flex flex-direction-v p-20 flex-v-center login-cont white-background">
        <h3 className="padded-bottom center-text m-b-20">
          Activate Profile
        </h3>
        <div className="w-100 m-b-20">
          <div className="w-100 flex space-between">
            <div className="w-100 p-r-10 m-b-20"><label htmlFor="firstName">First Name</label>
            <input onChange={(e) => handleChange(e)} className="w-100 p-10 "  type="name" name="firstName" id="firstName" /></div>
            <div className="w-100 m-b-20"><label htmlFor="lastName">Last Name</label>
            <input onChange={(e) => handleChange(e)} className="w-100 p-10 " type="name" name="lastName" id="lastName" /></div>
          </div>
  
          <label htmlFor="email">Email Address</label>
          <input onChange={(e) => handleChange(e)} className="w-100 p-10 m-b-20" type="email" name="email" id="email" />
          <label htmlFor="phoneNo">Phone Number</label>
          <input onChange={(e) => handleChange(e)} className="w-100 p-10 m-b-20" type="tel" name="phoneNo" id="phoneNumber" />
          <label htmlFor="party">Political Party</label>
          <SelectInput className="w-100 p-10 m-b-20" type="tel" onChange={(e) => setFormData({...formData, partyId:Number(e.target.value)})} name="partyId" id="partyId" options={parties} />
          <label htmlFor="password">Create Password</label>
          <input onChange={(e) => handleChange(e)} className="w-100 p-10 m-b-20" type="password" name="password" id="password" />
          <label  htmlFor="confirm_password">Confirm Password</label>
          <input onChange={(e) => handleChange(e)} className="w-100 p-10 m-b-20" type="password" name="confirmPassword" id="confirm_password" />
          <button className="w-100 background-gradient p-10 pointer" onClick={registerUser}>Login</button>
        </div>
        <div className="flex space-between w-100">
         <Link to={"/"}><p>Already have an account ?</p></Link>
         {/* <Link><p>Forgot Password</p></Link>  */}
          
        </div>
      </div>
    </div>
  );



}

export default SignUp;
