/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { RiBarChartFill, RiUserSettingsLine } from 'react-icons/ri';
import { BiEnvelope } from 'react-icons/bi';
import { CgUserList } from 'react-icons/cg';
import { BsCardList } from 'react-icons/bs';

export default [
  { title: 'Dashboard', href: '/patriot-manager', icon: <RiBarChartFill className="icon" /> },
  { title: 'Manage Candidate', href: '/patriot-manager/manage-candidate', icon: <BiEnvelope className="icon" /> },
  { title: 'Manage Canvassers', href: '/patriot-manager/manage-canvassers', icon: <RiUserSettingsLine className="icon" /> },
  {
    title: 'Election Result Manager',
    href: '/patriot-manager/result-manager',
    icon: <RiUserSettingsLine className="icon" />,
    children: [
      {
        title: 'View Live Result',
        href: '/patriot-manager/result-manager/view-live-result',
        icon: <BsCardList className="icon" />
      }
    ]
  },
  // { title: 'Target Setting', href: '/patriot-manager/target-setting', icon: <HiOutlineUserAdd className="icon" /> },
  // { title: 'Reporting', href: '/patriot-manager/reporting', icon: <CgUserList className="icon" /> },
  {
    title: 'Settings',
    href: '/patriot-manager/settings',
    icon: <CgUserList className="icon" />,
    children: [
      {
        title: 'Reset Password',
        href: '/patriot-manager/settings/reset-password',
        icon: <BsCardList className="icon" />
      },
      {
        title: 'Manage Election',
        href: '/patriot-manager/settings/manage-election',
        icon: <BsCardList className="icon" />
      }
    ]
  },
  { title: 'Logout', href: '/patriot-manager/logout', icon: <CgUserList className="icon" /> }

];
