import React, { useState } from 'react';
import { history } from 'history';
import TextInput from '../inputs/TextInput';
import { post } from '../../services/fetch';
import paths from '../../services/endpoints';
import cookies from 'js-cookie';
import notification from '../../utility/notification';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Spin } from 'antd';


const Login = (props) => {
  // const { history } = props;
  const history = useHistory();
  const [details, setDetails] = useState({
    email: 0,
    password: ''
  })
  const [loading, setLoading] = useState(false);


  const validateFields = () => {
    if (!details.email) {
      notification({
        title: 'Error',
        message: "Please enter a valid VIN",
        type: 'danger',
      });
      return false;
    }
    else if (!details.password) {
      notification({
        title: 'Error',
        message: "Please enter password",
        type: 'danger',
      });
      return false;
    }

    return true;
  }


  const login = async () => {
    let validated = validateFields();
    setLoading(true);


    if (!validated) {
      return;
    }
    else {
      const res = await post({ endpoint: `${paths.login}/?Vin=${details.email}&Password=${details.password}` })
      if (res?.data?.code === 1) {
        console.log(res);
        res?.data?.data?.email && cookies.set("email", res?.data?.data?.email)
        res?.data?.data?.userID && cookies.set("userID", res?.data?.data?.userID)
        res?.data?.data?.name && cookies.set("name", res?.data?.data?.name)
        res?.data?.data?.role && cookies.set("role", res?.data?.data?.role)
        res?.data?.data?.isActive && cookies.set(true)
        res?.data?.data?.isAdmin && cookies.set(true)
        res?.data?.data?.token && cookies.set("token", res?.data?.data?.token)
        res?.data?.data?.vin && cookies.set("vin", res?.data?.data?.vin)
        res?.data?.data?.phoneNo && cookies.set("phoneNo", res?.data?.data?.phoneNo)
        res?.data?.data?.phoneNo && cookies.set("phoneNo", res?.data?.data?.phoneNo)
        res?.data?.data?.partyId && cookies.set("partyId", res?.data?.data?.partyId)
        history.push("/patriot-manager/")
        setLoading(false);
      }
      else {
        notification({
          title: 'Error',
          message: res?.data?.message,
          type: 'danger',
          duration: 5000,
        });
        setLoading(false);
      }
    }

    setLoading(false);
  }



  const handleChange = (e) => {
    setDetails(prev =>
    ({
      ...prev,
      [e.target.name]: e.target.value
    })
    )
  }

  console.log(details)


  return (
    <div className="center">
      <div className="flex h-100">
        <div className="background-gradient flex-basis-60 m-r-20 login-border-radius">&nbsp;</div>
        <div className="flex flex-basis-40 flex-direction-v p-20 flex-v-center login-cont devview white-background">
          <h3 className="padded-bottom  center-text">
            Green Analytica
          </h3>
          <div className="w-100">
            <label htmlFor="email">VIN</label>
            <TextInput onChange={handleChange} className="w-100 m-b-20" type="email" name="email" id="email" />
            <label htmlFor="password">Password</label>
            <TextInput onChange={handleChange} className="w-100 m-b-20" type="password" name="password" id="password" />
            <button disabled={loading} className="w-100 background-gradient btn" onClick={login}>Login {loading && <Spin className='m-l-10'/>}</button>
          </div>
          <div className="flex space-between w-100 m-t-20">
            <Link to={"/signup"}><p>new to this admin portal ?</p></Link>
            <Link to={"/forgot-password"}><p>Forgot Password ?</p></Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
