/* eslint-disable react/destructuring-assignment */
import React from 'react';

const SelectInput = (props) => {
  const options =
    props.options &&
    [
      // { name: 'Select item', value: '', id: 0 },
      ...props.options,
    ].map((option) =>
      typeof option === 'object' ? (
        <option
          id={option.id ? option.id : null}
          value={
            option.value && option.value.toString().toLowerCase() === 'select item'
              ? ''
              : option.value
          }
          key={option.key || option.name}
          amount = {option?.amount}
          disabled={option?.name?.toLowerCase() === 'select item'}>
          {option.name}
        </option>
      ) : (
        <option
          value={
            option &&
            // typeof option === 'number' &&
            option.toString().toLowerCase() === 'select item...'
              ? ''
              : option
          }
          key={option}
          // disabled={option.toLowerCase() === 'select item'}
        >
          {!option ? 'select ...' : option}
        </option>
      ),
    );
  return (
    <div className={`${props.className} form-group`} style={{ ...props.overideStyle }}>
      <label htmlFor={props.name}>{props.label}</label>
      <select
        name={props.name}
        id={props.name}
        value={props.value}
        data-amount={props.amount}
        onChange={props.onChange}
        defaultValue={props?.defaultValue}
        onBlur={props.onBlur}
        disabled={props.disabled}
        placeholder={props?.placeholder}
        style={{ ...props.overideInputStyle }}>
        {options}
      </select>
    </div>
  );
};

export default SelectInput;
