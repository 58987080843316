import React, { useMemo, useState } from 'react';
import { IoMdClose } from 'react-icons/io';

import TextareaInput from '../inputs/TextareaInput';
import TextInput from '../inputs/TextInput';
import imayge from '../../assets/images/6e817f016538f2a246aa2864453da13c.png'
import { Input, Table } from 'antd';
import { render } from '@testing-library/react';
import { GrEdit } from 'react-icons/gr';
import { get, patch } from '../../services/fetch';
import notification from '../../utility/notification';
import generic from "../../assets/images/search-not-found.jpg"

const ViewUploadedReport = ({ closeModal, data, handleRecord, columnKey }) => {

    console.log(data)
    const [payload, setPayload] = useState(data);
    const [editingId, setEditingId] = useState(0);
    const [value, setValue] = useState(0);

    console.log(data)

    const handleError = (event) => {
        event.target.src = generic;
        // setImageSrc(generic);
    };

    // const retrieve = async (file) =>{
    //     console.log(file);
    //     let res = await get({ endpoint: "retrieve/" + file.replaceAll('\\', "%5C") })
    //     console.log(res.data.data)
    //     setPayload(res?.data?.data);

    //     return "https://edogoverp.com/electionpatriotsapi/api/retrieve/" + file.replaceAll('\\', "%5C")
    // }

    const handleUpdate = async (data, value) => {
        console.log(data);
        console.log(value);

        let res = await patch({
            endpoint: "Electionresult", body: {
                id: data?.id,
                votesReceived: [
                    {
                        partyId: data?.partyId,
                        votes: Number(value)
                    }
                ],
                pollingUnitId: data?.pollingUnitId,
                electionId: data?.electionId,
                registeredVoters: data?.registeredVoters,
                accreditedVoters: data?.accreditedVoters,
                validVote: data?.validVote,
                rejectedVote: data?.rejectedVote,
                returningOfficer: data?.returningOfficer,
                partyId: data?.partyId,
                votes: Number(value)
            }
        })

        if (res?.data?.code === 1) {
            notification({
                title: 'Success',
                message: res?.data?.message,
                type: 'success',
                duration: 5000,
            });

            console.log(res);
            setEditingId(0)
            setValue(0)
            handleRecord(columnKey);
        }
    }

    // const result = useMemo(() => retrieve(data[0].uploads[3]), []);

    // console.log(result);



    const edit = (record) => {
        console.log(record)
        if (editingId !== 0) {
            setEditingId(0)
        }
        else {

            setEditingId(record?.id)
        }

    }

    const columns = [
        { title: 'Party List', dataIndex: 'party', key: 'party' },
        {
            title: 'Total', dataIndex: 'votes', key: 'votes', render: (text, record) => (record?.id === editingId ? <div className='flex '>
                <Input className='m-r-10' onChange={(e) => setValue(e.target.value)} name={record?.party} />
                <button className='btn flex-basis-30' onClick={() => handleUpdate(record, value)}>update</button>
            </div> : record?.votes || 0)
        },
        { title: 'Edit', dataIndex: 'edit', key: 'edit', render: (text, record) => <GrEdit className='pointer' onClick={() => edit(record)} /> },

    ]

    return (
        <div className="overlay">
            <IoMdClose className="close-btn pointer" onClick={closeModal} />
            <div className="modal-box w-80">
                <div className="content flex-direction-v">
                    <h1 className='center-text m-b-40'>
                        Uploaded Report By Polling Agent
                    </h1>
                    <div className='grey-bg p-20'>
                        <div className='flex m-b-20'>
                            <div className='flex-basis-40'><b>Agent Name:</b> {data[0].initiator?.toUpperCase()}</div>
                            <div className='flex-basis-40'><b>LGA:</b> {data[0]?.lga}</div>
                            <div className='flex-basis-30'><b>Date: </b> {new Date(data[0]?.dateUploaded).toDateString()}</div>
                        </div>

                        <div className='flex m-b-20'>
                            <div className='flex-basis-40'> <b>Returning Officer:</b> {data[0]?.returningOfficer?.toUpperCase()}</div>
                            <div className='flex-basis-40'> <b>Ward:</b> {data[0].ward}</div>
                            <div className='flex-basis-30'><b>Time: </b> {new Date(data[0]?.dateUploaded).toTimeString()}</div>
                        </div>

                        <div className='flex '>
                            <div className='flex-basis-40'> <b>Registered Voters:</b> {data[0].registeredVoters}</div>
                            <div className='flex-basis-40'><b>Polling Unit:</b> {data[0]?.pollingUnit}</div>
                            <div className='flex-basis-30'></div>
                        </div>
                    </div>
                    <div className='flex m-t-20'>
                        <div className='flex-basis-30 m-r-20'>
                            <Table
                                dataSource={data && data}
                                columns={columns}
                            />
                        </div>
                        <div className='flex-basis-70 p-20'>
                            <img onError={handleError} height={"100%"} width={"100%"} src={"https://edogoverp.com/electionpatriotsapi/api/retrieve/" + data[0].uploads[0]?.replaceAll('\\', "%5C")} alt="" srcset="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewUploadedReport;
